import * as React from 'react'
import { Link } from 'gatsby'
import { Button } from 'antd'
import logo from '../images/findarishta-icon.png'
import Image from 'react-image-resizer'
import {
  InstagramOutlined,
  TwitterOutlined,
  QrcodeOutlined,
} from '@ant-design/icons'

const IndexPage = () => {
  return (
    <div align="center" style={{ backgroundColor: 'white' }}>
      {/* <Image
        img
        src={logo}
        alt="cur"
        height={350}
        width={700}
        style={{ alignSelf: 'center' }}
      /> */}
      <p
        style={{
          color: 'purple',
          fontSize: 40,
          fontWeight: 'bold',
        }}
      >
        As-salamu alaykum!
      </p>
      <p
        style={{
          color: 'pink',
          fontSize: 30,
          fontWeight: 'bold',
        }}
      >
        Welcome to findarishta.co.uk
      </p>
      <h2>
        Our mission is to connect individuals who are looking for a suitable
        Rishta.
      </h2>
      <h2>
        We've just started our community and would love for you to come over and
        join our discord channel, just click the link below to get started;
        we'll see you over there very soon!
      </h2>
      <p>Join our Discord server by clicking the get started button below.</p>
      <br />
      <Button.Group size="large">
        <Button
          size="large"
          icon={<TwitterOutlined />}
          href="https://twitter.com/UmarHus65572449"
          target="_blank"
        >
          Twitter
        </Button>
        <Button
          size="large"
          icon={<InstagramOutlined />}
          href="https://www.instagram.com/findarishta"
          target="_blank"
        >
          Instagram
        </Button>
        <Button
          size="large"
          icon={<QrcodeOutlined />}
          href="https://www.tiktok.com/@findarishta?lang=en"
          target="_blank"
        >
          TikTok
        </Button>

        <Button type="primary" size="large">
          <Link to="https://discord.gg/AzEpGaFhZs">Get Started</Link>
        </Button>
      </Button.Group>
      <p></p>
    </div>
  )
}

export default IndexPage
